import { useState, useEffect, useRef } from 'react';
import Loader from '@components/Loader';
import { exportProfiles } from '@utils/api/user';
import Button from '@components/Button';

export const ExportDataButton = (query) => {
  const [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    handleClick = async () => {
      setLoading(true);
      const csvFile = await exportProfiles(query.query);
      setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      setLoading(false);
    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
      setFileLink('');
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleClick} type="button" secondary outlined className="ml-2 my-1">
        Export
      </Button>
      <a href={fileLink} ref={linkRef} download="turnsignl-users.csv" className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};