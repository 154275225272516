import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { Input } from '@components/Form/Fields/Input';
import DateInput from '@components/Form/Fields/Date';
import EditableValue, { Value } from '@components/Form/Fields/EditableValue';
import Radio, { StatelessRadioComponent } from '@components/Form/Fields/Radio';
import CurrencyInput from '@components/Form/Fields/CurrencyInput';
import { Switch } from '@components/Form/Fields/Switch';
import Select from '@components/Form/Fields/Select';
import withField from '@components/Form/Fields/withField';
import ToggleForm from '@components/Form/ToggleForm';
import {
  CardModalButtonContainer,
  SubscriptionModalButton,
  BusinessSubscriptionInfo,
  PaymentButtons
} from '@components/Business/View';
import RequestSelect from '@containers/RequestSelect';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import SearchSelect from '@components/Form/Fields/SearchSelect';
import Section from '@components/Section';
import {
  roleOptions,
  lawFirmRoleOptions,
  lawFirmSelector,
  nonLawFirmSelector,
  BusinessActiveField,
  RoleWatcher,
  businessRoleOptions
} from '@components/User/Form';
import { useRequestState } from '@containers/RequestContext';
import { usePaginationRequestState } from '@containers/PaginationContext';
import AppInfoSelect from '@containers/AppInfoSelect';
import StateSelect from '@containers/StateSelect';
import StateValue from '@containers/StateValue';
import PracticeSelect from '@containers/PracticeSelect';
import withPageTitle from '@containers/withPageTitle';
import { updateUser, updateUserLawyer } from '@utils/api/user';
import { updateUserSchema } from '@utils/validation/user';
import { makeRoleString, cashFormat, phoneFormat, getFormattedDate, getAge } from '@utils';
import { useAuthState } from '@containers/Auth';
import stripePromise from '@utils/stripe';
import styles from './index.module.css';
import { resendEmailRequest } from '@utils/api/auth';
import { useToastAddAction } from '@containers/ToastContext';
import { useLoaderDispatch } from '@containers/Loader';
import Button from '@components/Button';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import { classNames } from '@utils';
import Modal from '@components/Modal';
import { getRequest } from '@utils/api';
import ContractTypeSelect from '@containers/ContractTypeSelect';

const getBirthdate = (date) => {
  if (!date) return date;
  const [y, m, d] = date.split('-');
  return Number(m) + '/' + Number(d) + '/' + Number(y);
};
const makeBirthdate = (date) => {
  if (!date) return date;
  const [y, m, d] = date.split('-');
  return new Date(Number(y), Number(m) - 1, Number(d));
};
const SwitchField = withField(Switch, undefined, true, true);
const makeLawyer = ({ state_ids, states, ...lawyer }) => ({
  ...lawyer,
  state_ids: states.map((state) => ({ value: state.id, label: state.abbrev }))
});

const makeUserFormValues = ({
  vehicle,
  business,
  status,
  lawyer,
  active,
  business_active,
  birthdate,
  state,
  race_option,
  gender_option,
  employment_option,
  ...user
}) => ({
  ...user,
  vehicle_attributes: vehicle,
  //business_attributes: business,
  birthdate: makeBirthdate(birthdate),
  role: makeRoleString({ business, ...user }),
  business_id: business?.id,
  state_id: state?.id,
  active: active ? 'true' : 'false',
  business_active: business_active ? 'true' : 'false',
  employment_option_id: employment_option?.id,
  gender_option_id: gender_option?.id,
  race_option_id: race_option?.id,
  ...(lawyer ? { lawyer_attributes: makeLawyer(lawyer) } : {})
});
const makeDeviceIconClassName = (os) => {
  if (os === 'iOS') {
    return 'la-apple';
  } else if (os === 'Android') {
    return 'la-android';
  }
  return 'la-desktop';
};
const Device = ({ device, idx }) => (
  <div className={idx > 0 ? 'border-t border-gray-300 dark:border-gray-900' : undefined}>
    <div className="flex items-center py-5">
      <div className={styles.deviceIcon + ` la ${makeDeviceIconClassName(device.os)}`}></div>
      <div className={styles.deviceOS}>{device.os}</div>
    </div>
    <SwitchField
      disabled
      className={styles.deviceSwitch}
      label="Allow TurnSignl access to Microphone"
      value={device.microphone_active}
    />
    <SwitchField
      disabled
      className={styles.deviceSwitch}
      label="Allow TurnSignl access to Camera"
      value={device.camera_active}
    />
    <SwitchField
      disabled
      className={styles.deviceSwitch}
      label="Allow TurnSignl access to Location"
      value={device.location_active}
    />
    <SwitchField
      disabled
      className={styles.deviceSwitch}
      label="Allow TurnSignl to send Notifications"
      value={device.notification_active}
    />
    <SwitchField
      disabled
      className={styles.deviceSwitch}
      label="Allow TurnSignl to send VOIP"
      value={device.voip_ready}
    />
  </div>
);

const SubscriptionSection = ({
  user,
  makeRequest,
  hasUserSubscriptionInfo,
  hasBusinessSubscriptionInfo
}) =>
  hasUserSubscriptionInfo ? (
    <>
      <div className="lg:w-1/4 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
        <Value label="Plan">{user.subscription.plan.name}</Value>
        <div className="lg:flex">
          <div className="lg:w-1/2 lg:pr-2">
            <Value label="Charges">
              {cashFormat(user.subscription.plan.amount)} {user.subscription.plan.billed}
            </Value>
          </div>
          {user.subscription.coupon_code && (
            <div className="lg:w-1/2 lg:px-2">
              <Value label="Coupon Code">{user.subscription.coupon_code}</Value>
            </div>
          )}
        </div>
        <Value label="Subscription Payment Status">
          {user.subscription?.subscription_status || 'N/A'}
        </Value>
      </div>
      {!user.has_business_subscription && (
        <PaymentButtons
          className="lg:px-4"
          url={`/user/${user.id}`}
          pollUrl={`/users/${user.id}.json`}
          paymentUrl={`/user/${user.id}/payments.json`}
          planId={user.subscription.plan.plan_id}
          makeRequest={makeRequest}
          isUpdating={user.payment_methods?.length > 0}
          showSubscriptionButton={user.payment_methods?.length > 0}
          showCancelSubscriptionButton={user.subscription?.subscription_status === 'ACTIVE'}
        />
      )}
    </>
  ) : hasBusinessSubscriptionInfo ? (
    <BusinessSubscriptionInfo
      business={user.business}
      className="lg:w-1/3 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900"
    />
  ) : !user.has_business_subscription ? (
    <div className="lg:w-1/3 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
      <PaymentButtons
        className="lg:px-4"
        url={`/user/${user.id}`}
        pollUrl={`/users/${user.id}.json`}
        paymentUrl={`/user/${user.id}/payments.json`}
        planId={user.subscription.plan.plan_id}
        makeRequest={makeRequest}
        isUpdating={user.payment_methods?.length > 0}
        showSubscriptionButton={user.payment_methods?.length > 0}
        showCancelSubscriptionButton={user.subscription?.subscription_status === 'ACTIVE'}
      />
    </div>
  ) : null;

const AccountStatusValues = ({ user, isEditing, makeRequest, pagination, updateData }) => {
  const currentUser = useAuthState();
  const addToast = useToastAddAction();
  const canAddPaymentMethod =
    user.needs_payment_method && !user.has_payment_method && !user.has_business_subscription,
    hasUserSubscriptionInfo = user.subscription?.plan?.plan_id,
    hasBusinessSubscriptionInfo =
      user.has_business_subscription && user?.business?.organization_subscription?.id,
    hasSubscriptionInfo = hasUserSubscriptionInfo || hasBusinessSubscriptionInfo,
    subscriptionStatus = hasUserSubscriptionInfo
      ? user.subscription.subscription_status
      : hasBusinessSubscriptionInfo
        ? user.business.organization_subscription.subscription_status
        : 'N/A';

  const resendEmail = async (confirmUser) => {
    if (confirmUser.can_resend_instructions) {
      const response = await resendEmailRequest(confirmUser);
      addToast(response.message);
    } else {
      addToast('It is not available user invitation');
    }
  };

  return (
    <div className="lg:flex">
      <div className="lg:w-1/3 lg:border-r border-gray-300 dark:border-gray-900">
        <Value label="Status">{user.status}</Value>
        {/*<Value label="Sub-Status">{subscriptionStatus}</Value>*/}
        <div className="lg:flex">
          <div className="lg:w-1/2 lg:pr-2">
            <Value label="Subscribe Date">
              {hasSubscriptionInfo
                ? hasUserSubscriptionInfo
                  ? user.subscription.start_date && getFormattedDate(user.subscription.start_date)
                  : user.business.organization_subscription.start_date &&
                  getFormattedDate(user.business.organization_subscription.start_date)
                : 'N/A'}
            </Value>
            <Value label="Last Invoice Date">
              {user.subscription?.invoice_dates &&
                user.subscription.invoice_dates?.last_invoice_date
                ? getFormattedDate(user.subscription.invoice_dates?.last_invoice_date)
                : 'N/A'}
            </Value>
          </div>
          <div className="lg:w-1/2 lg:px-2">
            <Value label="Unsubscribed Date">
              {hasSubscriptionInfo
                ? hasUserSubscriptionInfo
                  ? user.subscription.end_date && getFormattedDate(user.subscription.end_date)
                  : user.business.organization_subscription.end_date &&
                  getFormattedDate(user.business.organization_subscription.end_date)
                : 'N/A'}
            </Value>
            <Value label="Next Invoice Date">
              {user.subscription?.invoice_dates &&
                (user.subscription.invoice_dates?.next_invoice_date
                  ? getFormattedDate(user.subscription.invoice_dates?.next_invoice_date)
                  : 'N/A')}
            </Value>
          </div>
        </div>
        <EditableValue
          defaultValue={user.active}
          value={
            <StatelessRadioComponent
              as="input"
              checked={user.active}
              radioLabel="Active"
              radioLabelTwo="Inactive"
              disabled
            />
          }
          label="Account Active"
          as={Radio}
          isEditing={isEditing && !currentUser.is_org_admin}
          name="active"
          radioLabel="Active"
          radioLabelTwo="Inactive"
        />
      </div>

      <div className="lg:w-1/3 lg:px-4">
        <Value label="Email Confirmation (Sign-Up)">
          {user.can_resend_instructions ? 'NOT ACCEPTED' : 'ACCEPTED'}
          <Button
            onClick={() => resendEmail(user)}
            primary
            className="ml-2"
            disabled={user.can_resend_instructions ? false : true}
          >
            Resend Confirmation Email
          </Button>
        </Value>
        <Value label="Email Confirmation (Subscribe)">
          {hasSubscriptionInfo
            ? hasUserSubscriptionInfo
              ? user.subscription.confirm_status
              : hasBusinessSubscriptionInfo
                ? user.business.organization_subscription.confirm_status
                : 'N/A'
            : 'N/A'}
        </Value>
        <Value label="Dedicated Attorney">
          {user.dedicated_lawyers.length > 0 && (user.dedicated_lawyers[0]?.user?.first_name + " " + user.dedicated_lawyers[0]?.user?.last_name)}
          <SelectLawyer url={`/users/${user.id}/lawyers.json`} updateUrl={`/users/${user.id}.json`} pagination={pagination} updateData={updateData} addToast={addToast} setAttorney={user.dedicated_lawyers.length > 0 ? user.dedicated_lawyers[0].id : null} />
        </Value>
      </div>
      {canAddPaymentMethod ? (
        <div className="lg:w-1/3 flex items-center justify-center lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
          <CardModalButtonContainer
            pollUrl={`/users/${user.id}.json`}
            onSuccess={makeRequest}
            url={`/user/${user.id}/payments.json`}
          />
        </div>
      ) : (
        <SubscriptionSection
          user={user}
          makeRequest={makeRequest}
          hasUserSubscriptionInfo={hasUserSubscriptionInfo}
          hasBusinessSubscriptionInfo={hasBusinessSubscriptionInfo}
        />
      )}
    </div>
  );
};

export const SelectLawyer = ({ url, updateUrl, pagination, updateData, addToast, setAttorney }) => {
  const loaderDispatch = useLoaderDispatch(),
    [isOpen, setIsOpen] = useState(),
    [isChecked, setIsCheck] = useState(setAttorney),
    [lawyerlist, setLawyerList] = useState([]),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    handleSuccess = async () => {
      try {
        loaderDispatch({ type: 'START_LOADING' });
        const response = await updateUserLawyer(user, updateUrl)
        pagination.updateData({
          ...pagination.data,
          users: pagination.data.users.map((u) => {
            if (u.id === response.id) return Object.assign({}, u, response);
            return u;
          })
        });
        updateData(response);
        handleClose();
        loaderDispatch({ type: 'STOP_LOADING' })
      } catch (e) {
        loaderDispatch({ type: 'STOP_LOADING' });
        addToast('An unexpected error has occurred');
        handleClose();
      }
    },
    makeRequest = async () => {
      try {
        const response = await getRequest(url);
        setLawyerList(response.lawyers)
      } catch (e) {
        console.log('error', e);
      }
    };
  useEffect(() => {
    makeRequest(url)
  }, [url]);
  return (
    <>
      <Button
        onClick={handleOpen}
        primary
        className="ml-2"
      >
        Select
      </Button>
      <Modal
        isOpen={isOpen}
        title="Select Lawer"
        onContinueClick={handleSuccess}
        close={handleClose}
        form
      >
        <table style={{
          borderCollapse: 'collapse',
          width: '100%'
        }}>
          <tr style={{
            display: 'flex', width: '100%', padding: '8px 0px 8px 0px', textAlign: 'left',
            boxSizing: 'border-box',
            borderBottomWidth: 0.5,
            borderStyle: 'solid',
          }}>
            <th style={{ width: '30%' }}>Lawyer name</th>
            <th style={{ width: '30%' }}>Firm name</th>
            <th style={{ width: '30%' }}>Practice type</th>
            <th style={{ width: '10%' }}>Action</th>
          </tr>
          {lawyerlist.map((item) => {
            return (
              <tr style={{ display: 'flex', width: '100%', padding: '8px 0px 8px 0px' }}>
                <td style={{ width: '30%' }}>{item?.user?.first_name + " " + item?.user?.last_name}</td>
                <td style={{ width: '30%' }}>{item?.firm_name}</td>
                <td style={{ width: '30%' }}>{item?.practice_type?.name}</td>
                <td style={{
                  width: '8%',
                  display: 'flex',
                  justifContent: 'center'
                }}>
                  <input type={'checkbox'} checked={isChecked === item?.id ? true : false} onChange={() => { setIsCheck(isChecked => isChecked === item?.id ? null : item.id) }} />
                </td>
              </tr>
            )
          }
          )}
        </table>
      </Modal>
    </>
  );
};

const showClientBusinessField = (role, user, isLawFirmOrgAdmin) => {
  if (role) {
    return role === 'Client';
  }
  return !user.is_lawyer && !user.is_admin && !user.is_org_admin;
};
const showLawyerAttributeFields = (role, user, isLawFirmOrgAdmin) => {
  if (role) {
    return role === 'Attorney' || role === 'Attorney Admin';
  }
  return user.is_lawyer;
};
const LawyerFields = ({ role, user, isEditing, isLawFirmOrgAdmin }) => {
  const currentUser = useAuthState();
  return (
    <>
      {showClientBusinessField(role, user, isLawFirmOrgAdmin) && (
        <div className="lg:flex">
          <div className="lg:w-1/2 lg:pr-2">
            <EditableValue
              as={RequestSelect}
              url="/business/names.json"
              resource={nonLawFirmSelector}
              label="Business"
              placeholder="Select Business..."
              name="business_id"
              value={
                user.business?.name &&
                (!currentUser.is_org_admin ? (
                  <Link to={`/businesses/${user.business.id}`}>{user.business.name}</Link>
                ) : (
                  <span>{user.business.name}</span>
                ))
              }
              isEditing={isEditing && !currentUser.is_org_admin}
            />
          </div>
          <div className="lg:w-1/2 lg:px-2">
            {isEditing && (currentUser.is_admin || currentUser.is_org_admin) ? (
              <BusinessActiveField />
            ) : user.business?.id ? (
              <Value label="Business Active">
                <StatelessRadioComponent
                  as="input"
                  checked={user.business_active}
                  radioLabel="Active"
                  radioLabelTwo="Inactive"
                  disabled
                />
              </Value>
            ) : null}
          </div>
        </div>
      )}
      {showLawyerAttributeFields(role, user, isLawFirmOrgAdmin) && (
        <div className="lg:flex">
          <div className="lg:w-1/2 lg:pr-2">
            <EditableValue
              as={PracticeSelect}
              value={user.lawyer?.practice_type?.name}
              label="Specialty"
              placeholder="Select Specialty..."
              root="lawyer_attributes"
              isEditing={isEditing}
            />
          </div>
          <div className="lg:w-1/2 lg:px-2">
            {isEditing && user.lawyer?.id && (
              <Input className="hidden" name="lawyer_attributes[id]" />
            )}
            <EditableValue
              as={MultiStateSelect}
              value={user.lawyer?.states.map(({ abbrev }) => abbrev).join(', ')}
              label="Licensed States"
              root="lawyer_attributes"
              name="state_ids"
              placeholder="Search States..."
              isEditing={isEditing}
            />
          </div>
        </div>
      )}
    </>
  );
};

const showBusinessFields = (role, user, isLawFirmOrgAdmin) => {
  if (role) {
    return role === 'Attorney' || role === 'Attorney Admin' || role === 'Business Admin';
  }
  return user.is_lawyer || user.is_org_admin;
};

const showUserValueFields = (role, user) => {
  if (role) {
    return role !== 'Admin';
  }
  return !user.is_admin;
};
const lawFirmSelectorIfTrue = (bool) => (bool ? lawFirmSelector : nonLawFirmSelector);
const makeBusinessOptions = (role, user, isLawFirmOrgAdmin) => {
  if (role) {
    return lawFirmSelectorIfTrue(role === 'Attorney' || role === 'Attorney Admin');
  }
  return lawFirmSelectorIfTrue(user.is_lawyer || isLawFirmOrgAdmin);
};
const UserResourceFields = ({ role, user, isEditing, isLawFirmOrgAdmin }) => {
  const currentUser = useAuthState();
  return showBusinessFields(role, user, isLawFirmOrgAdmin) ? (
    <div className="lg:w-1/2 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
      <div className="lg:flex">
        <div className="lg:w-1/2 lg:pr-2">
          <EditableValue
            as={RequestSelect}
            url="/business/names.json"
            resource={makeBusinessOptions(role, user, isLawFirmOrgAdmin)}
            label="Business"
            name="business_id"
            placeholder="Select Business..."
            value={
              user.business?.name &&
              (!currentUser.is_org_admin ? (
                <Link to={`/businesses/${user.business.id}`}>{user.business.name}</Link>
              ) : (
                <span>{user.business.name}</span>
              ))
            }
            isEditing={isEditing && !currentUser.is_org_admin}
          />
        </div>
        <div className="lg:w-1/2 lg:px-2">
          {isEditing && (currentUser.is_admin || currentUser.is_org_admin) ? (
            <BusinessActiveField />
          ) : (
            <Value label="Business Active">
              <StatelessRadioComponent
                as="input"
                checked={user.business_active}
                radioLabel="Active"
                radioLabelTwo="Inactive"
                disabled
              />
            </Value>
          )}
        </div>
      </div>
      <div className="lg:flex w-full">
        <div className="lg:w-1/2 lg:pr-2">
          <Value label="Business Type">{user.business?.business_type?.name}</Value>
        </div>
        <div className="lg:w-1/2 lg:px-2">
          <Value label="Business Sub-Type">{user.business?.business_subtype?.name}</Value>
        </div>
      </div>
      <div className="lg:flex w-full">
        <Value label="Business Address">{user.business?.address1 ? user.business.address1 : ' '}</Value>
      </div>
      <div className="lg:flex w-full">
        <Value>{user.business?.address2 ? user.business.address2 : ' '}</Value>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/3 lg:pr-2">
          <Value label="City">{user.business?.city}</Value>
        </div>
        <div className="lg:w-1/3 lg:px-2">
          <Value label="State">
            <StateValue id={user.business.state_id} />
          </Value>
        </div>

        <div className="lg:w-1/3 lg:px-2">
          <Value label="Zip Code">{user.business?.zip_code}</Value>
        </div>
      </div>
      <div className="lg:flex">
        <div className="lg:w-1/2 lg:pr-2">
          <EditableValue
            value={user.business?.phone && phoneFormat(user.business?.phone)}
            as={PhoneNumberInput}
            isEditing={isEditing}
            name="phone"
            label="Phone Number"
            placeholder="Phone"
          />
        </div>
      </div>
    </div>
  ) : (
    showUserValueFields(role, user) && (
      <>
        <div className="lg:w-1/4 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
          <EditableValue
            value={user.birthdate && getBirthdate(user.birthdate)}
            isEditing={isEditing}
            name="birthdate"
            type="date"
            label="Birthday"
            as={DateInput}
          />
          {isEditing ? (
            <Value label="Age">
              <AgeValueWatcher />
            </Value>
          ) : (
            <Value label="Age">{user.birthdate ? getAge(user.birthdate) : 'N/A'}</Value>
          )}
          <EditableValue
            value={user.gender_option?.name}
            isEditing={isEditing}
            name="gender_option_id"
            as={AppInfoSelect}
            resource="gender_options"
            label="Gender"
            placeholder="Select Gender..."
          />
          <EditableValue
            value={user.race_option?.name}
            isEditing={isEditing}
            name="race_option_id"
            as={AppInfoSelect}
            resource="race_options"
            label="Race"
            placeholder="Select Race..."
          />
        </div>
        {(user.vehicle?.id || isEditing) && (
          <div className="lg:w-1/4 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900">
            {isEditing && user.vehicle?.id && (
              <Input className="hidden" name="vehicle_attributes[id]" />
            )}
            <EditableValue
              value={user.vehicle.make}
              isEditing={isEditing}
              name="vehicle_attributes[make]"
              label="Make"
              placeholder="Make"
            />
            <EditableValue
              value={user.vehicle.model}
              isEditing={isEditing}
              name="vehicle_attributes[model]"
              label="Model"
              placeholder="Model"
            />
            <EditableValue
              value={user.vehicle.color}
              isEditing={isEditing}
              name="vehicle_attributes[color]"
              label="Color"
              placeholder="Color"
            />
            <EditableValue
              value={user.vehicle.year}
              isEditing={isEditing}
              name="vehicle_attributes[year]"
              label="Year"
              placeholder="Year"
            />
          </div>
        )}
      </>
    )
  );
};

const showUserEmploymentFields = (role, user) => {
  if (user.is_org_admin || role === 'Client') {
    return false;
  }

  return !user.is_admin && !user.is_lawyer && !user.is_org_admin;
};

const UserEmploymentFields = ({ role, user, isEditing }) =>
  showUserEmploymentFields(role, user) ? (
    <Section title="Others" initialIsOpen border>
      <div className="lg:flex">
        <div className="lg:w-1/4 lg:pr-2">
          <EditableValue
            value={user.employment_option?.name}
            isEditing={isEditing}
            name="employment_option_id"
            as={AppInfoSelect}
            resource="employment_options"
            label="Employment Status"
            placeholder="Select Employment Status..."
          />
        </div>
        <div className="lg:w-1/4 lg:px-2">
          <EditableValue
            value={user.pay_per_hour && cashFormat(user.pay_per_hour)}
            isEditing={isEditing}
            name="pay_per_hour"
            as={CurrencyInput}
            label="Hourly Rate"
            placeholder="Hourly Rate"
          />
        </div>
        <div className="lg:w-1/4 lg:px-2">
          <EditableValue
            value={user.hours_per_week}
            type="number"
            pattern="[0-9]*"
            min={0}
            inputMode="numeric"
            isEditing={isEditing}
            name="hours_per_week"
            label="# of Hours per Week"
          />
        </div>
        <div className="lg:w-1/4 lg:px-2">
          <EditableValue
            value={user.dependents}
            type="number"
            pattern="[0-9]*"
            min={0}
            inputMode="numeric"
            isEditing={isEditing}
            name="dependents"
            label="# of Dependents"
          />
        </div>
      </div>
    </Section>
  ) : null;

const RoleValueComponent = ({ component: Component, ...props }) => {
  const role = useWatch({
    name: 'role'
  });
  return <Component {...props} role={role} />;
};

const AgeValueWatcher = () => {
  const birthdate = useWatch({
    name: 'birthdate'
  });
  return birthdate ? getAge(birthdate) : 'N/A';
};
const MultiStateSelect = ({ isMulti = true, ...props }) => (
  <StateSelect as={SearchSelect} {...props} isMulti={isMulti} placement="top-start" />
);
const UserPage = () => {
  const currentUser = useAuthState();
  const { data, updateData, makeRequest } = useRequestState(),
    pagination = usePaginationRequestState(),
    isLawFirmOrgAdmin = data.is_org_admin && data.business?.is_law_firm,
    handleSuccess = ({ response }) => {
      pagination.updateData({
        ...pagination.data,
        users: pagination.data.users.map((u) => {
          if (u.id === response.id) return Object.assign({}, u, response);
          return u;
        })
      });
      updateData(response);
    };
  return (
    <ToggleForm
      formProps={{
        url: `/users/${data.id}.json`,
        defaultValues: makeUserFormValues(data),
        onSubmit: updateUser,
        onSuccess: handleSuccess,
        to: false,
        toast: 'User updated',
        validationSchema: updateUserSchema
      }}
    >
      {(isEditing) => (
        <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
          <Section title="Profile Details" collapsible={false} initialIsOpen>
            <div className="lg:flex">
              <div className="lg:w-1/2 lg:pr-2">
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.first_name}
                      isEditing={isEditing}
                      name="first_name"
                      label="First Name"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <EditableValue
                      value={data.last_name}
                      isEditing={isEditing}
                      name="last_name"
                      label="Last Name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.email}
                      isEditing={isEditing}
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <EditableValue
                      value={data.phone && phoneFormat(data.phone)}
                      as={PhoneNumberInput}
                      isEditing={isEditing}
                      name="phone"
                      label="Phone"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.recovery_email}
                      isEditing={isEditing}
                      name="recovery_email"
                      type="recovery_email"
                      label="Recovery Email"
                      placeholder="Recovery Email"
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.address1}
                      isEditing={isEditing}
                      label="Address Line 1"
                      name="address1"
                      placeholder="Address Line 1"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <EditableValue
                      value={data.address2}
                      isEditing={isEditing}
                      label="Address Line 2"
                      name="address2"
                      placeholder="Address Line 2"
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/3 lg:pr-2">
                    <EditableValue
                      value={data.city}
                      isEditing={isEditing}
                      label="City"
                      name="city"
                      placeholder="City"
                    />
                  </div>
                  <div className="lg:w-1/3 lg:px-2">
                    <EditableValue
                      value={data.state.abbrev}
                      as={StateSelect}
                      isEditing={isEditing}
                      label="State"
                      name="state_id"
                      placeholder="Select State..."
                    />
                  </div>
                  <div className="lg:w-1/3 lg:px-2">
                    <EditableValue
                      value={data.zipcode}
                      isEditing={isEditing}
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      inputMode="numeric"
                      name="zipcode"
                      label="Zip Code"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>

                <div className="lg:flex">
                  {makeRoleString(data) !== 'Admin' ? (
                    <div className={data.user_type !== "Primary" ? "lg:w-1/3 lg:pr-2" : "lg:flex w-full"}>
                      <EditableValue
                        value={makeRoleString(data)}
                        as={Select}
                        isEditing={isEditing}
                        label="Role"
                        name="role"
                        placeholder="Select Role..."
                        options={
                          data.lawyer?.id
                            ? lawFirmRoleOptions
                            : currentUser.is_org_admin
                              ? businessRoleOptions
                              : roleOptions
                        }
                      />
                    </div>
                  ) : null}
                  <div className={data.user_type !== "Primary" ? "lg:w-1/3 lg:pr-2" : "lg:flex w-full"}>
                    <Value label="LegalEase Member Id">{data?.legalease_member_id}</Value>
                  </div>
                  {makeRoleString(data) === 'Attorney Admin' || makeRoleString(data) === 'Attorney' ? (
                    <div className={"lg:w-1/3 lg:pr-2"}>
                      <EditableValue
                        value={data?.lawyer?.contract_type}
                        as={ContractTypeSelect}
                        isEditing={isEditing}
                        label="Contract Type"
                        name="lawyer_attributes.contract_type"
                        placeholder="Select Contract Type..."
                      />
                    </div>
                  ) : null}
                  {data.user_type !== "Primary" && <div className="lg:w-1/3 lg:pr-2">
                    <EditableValue
                      value={data.user_type}
                      isEditing={false}
                      label="User Type"
                      name="type"
                      placeholder="User Type"
                    />
                  </div>}
                  {data.user_type !== "Primary" && <div className="lg:w-1/3 lg:pr-2">
                    <EditableValue
                      value={<Link to={`/users/${data.primary_user.id}`}>{data.primary_user.first_name + ' ' + data.primary_user.last_name}</Link>}
                      isEditing={false}
                      label="Primary User"
                      name="primary_user"
                      placeholder="Primary User"
                    />
                  </div>}
                </div>


                {isEditing ? (
                  <RoleValueComponent
                    component={LawyerFields}
                    user={data}
                    isLawFirmOrgAdmin={isLawFirmOrgAdmin}
                    isEditing
                  />
                ) : (
                  <LawyerFields user={data} isLawFirmOrgAdmin={isLawFirmOrgAdmin} />
                )}
              </div>
              {isEditing ? (
                <RoleValueComponent
                  component={UserResourceFields}
                  user={data}
                  isLawFirmOrgAdmin={isLawFirmOrgAdmin}
                  isEditing
                />
              ) : (
                <UserResourceFields
                  user={data}
                  isLawFirmOrgAdmin={isLawFirmOrgAdmin}
                  role={makeRoleString(data)}
                />
              )}
            </div>
          </Section>
          <Section title="Account Status" initialIsOpen border>
            <AccountStatusValues user={data} makeRequest={makeRequest} isEditing={isEditing} pagination={pagination} updateData={updateData} />
          </Section>
          {data.user_devices?.length > 0 && (
            <Section title="Preferences (Permissions)" initialIsOpen border>
              {data.user_devices.map((device, i) => (
                <Device key={device.id} device={device} idx={i} />
              ))}
            </Section>
          )}
          {isEditing ? (
            <RoleValueComponent component={UserEmploymentFields} user={data} isEditing />
          ) : (
            <UserEmploymentFields user={data} role={makeRoleString(data)} />
          )}
          {isEditing && <RoleWatcher />}
          {(data.user_type === "Primary" && data.family_plan_active) && <Section title={
            <>
              <div>Family Members</div>
              <div className={styles.referralCode}>Max Family Members: {data?.max_family_members || ''}</div>
            </>
          }
            collapsible={false}
            initialIsOpen
            border
          >
            {data?.family_members?.length > 0 ? (
              <div className={classNames(styles.tableWrapper, 'scrollbar')}>
                <table className="table w-full mt-3">
                  <thead>
                    <tr>
                      <th className="text-left uppercase">Email</th>
                      <th className="text-left uppercase">Name</th>
                      <th className="text-left uppercase">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.family_members.map((item) => (
                      <tr key={item.id}>
                        <td className="text-left">{item.email}</td>
                        <td className="text-left">
                          <Link to={`/users/${item.id}`}>{item.first_name + ' ' + item.last_name}</Link>
                        </td>
                        <td className="text-left">{item.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyResourcePlaceholder text="There is nothing here" />
            )}
          </Section>}
          <Section title={
            <>
              <div>Referrals</div>
              <div className={styles.referralCode}>
                Referral Code: <b>{data?.referral_code || ''}</b>
                <br />
                {data?.referral_link ? (
                  <a href={data.referral_link}>{data.referral_link}</a>
                ) : null
                }
              </div>
            </>
          }
            collapsible={false}
            initialIsOpen
            border
          >
            <h1></h1>
            {data?.referrals?.length > 0 ? (
              <div className={classNames(styles.tableWrapper, 'scrollbar')}>
                <table className="table w-full mt-3">
                  <thead>
                    <tr>
                      <th className="text-left uppercase">Email</th>
                      <th className="text-left uppercase">First Name</th>
                      <th className="text-left uppercase">Last Name</th>
                      <th className="text-left uppercase">Signed Up</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.referrals.map((ref_user) => (
                      <tr key={ref_user.id}>
                        <td className="text-left">{ref_user.email}</td>
                        <td className="text-left">{ref_user.first_name}</td>
                        <td className="text-left">{ref_user.last_name}</td>
                        <td className="text-left">{getFormattedDate(ref_user.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyResourcePlaceholder text="There is nothing here" />
            )}
          </Section>
        </div>
      )}
    </ToggleForm>
  );
};

export default withPageTitle(UserPage, 'User Profile');
