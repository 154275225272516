export function classNames() {
  return [...arguments].filter((s) => typeof s === 'string').join(' ');
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const uniq = (a) => a.filter(onlyUnique);

export const makeDateString = (date) => {
  if (!date) return date;
  const [y, m, d] = date.split('-');
  return `${m[0] === '0' ? m[1] : m}/${d[0] === '0' ? d[1] : d}/${y.substring(2)}`;
};

export const makeRoleString = (user) => {
  if (user.is_org_admin) {
    if (user.is_lawyer) {
      return 'Attorney Admin';
    } else {
      return 'Business Admin';
    }
  } else if (user.is_admin) {
    return 'Admin';
  } else if (user.is_lawyer) {
    return 'Attorney';
  } else {
    return 'Client';
  }
};

export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const capitalize = (s) =>
  s
    ? s
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ')
    : '';

export function clean(obj) {
  let stringfiedObj = JSON.stringify(obj, (key, value) => {
    return ['', null].includes(value) ||
      (typeof value === 'object' && (value.length === 0 || Object.keys(value).length === 0))
      ? undefined
      : value;
  });
  let resObj = JSON.parse(stringfiedObj);
  let isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key));
  if (isEmptyPropsPresent) {
    return clean(resObj);
  }
  return resObj;
}

export const getFormattedDate = (date) => {
  if (!date) return date;
  var d = new Date(date);
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return month + '/' + day + '/' + year;
};

export const getTime = (date) => {
  if (!date) return date;
  const d = new Date(date),
    minute = d.getMinutes();
  var hour = d.getHours(),
    ampm = 'AM';

  if (hour > 11) {
    ampm = 'PM';
  }
  if (hour > 12) {
    hour -= 12;
  }
  return `${hour === 0 ? 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${ampm}`;
};

export function getDuration(a, b) {
  if (!a || !b) return;
  var d2 = new Date(a).getTime(),
    d1 = new Date(b).getTime(),
    hourDiff = (d2 - d1) / 1000;
  hourDiff /= 60 * 60;

  const minuteDiff = (hourDiff % 1) * 60,
    second = Math.abs(Math.round((minuteDiff % 1) * 60)),
    minute = Math.abs(parseInt(minuteDiff)),
    hour = Math.abs(parseInt(hourDiff));
  return `${hour ? `${hour}h ` : ''}${minute ? `${minute}m ` : ''}${second ? `${second}s` : ''}`;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const makeDateStr = (date) => {
  if (!date) return date;
  var d = new Date(date);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const phoneFormat = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const makeFileReqObj = async (file, name = 'asset') => {
  const obj = {};
  if (!file) {
    obj[name] = null;
  } else if (typeof file !== 'string') {
    obj[name] = await getBase64(file[0]);
  }
  return obj;
};

export const cashFormat = (v) => {
  const number = Number(v);
  if (!number) return null;
  return '$' + number.toFixed(2);
};

export function dateOnly(dt) {
  const d = new Date(dt);
  d.setHours(0, 0, 0, 0);
  return d.getTime();
}

export const stringifyQuery = (obj) => {
  if (!obj) return '';
  var qs = '';
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        for (var i = 0; i < obj[key].length; i++) {
          qs += `&${key}=${obj[key][i]}`;
        }
      } else if (obj[key] === null) {
        qs += `&${key}=`;
      } else if (obj[key] !== undefined) {
        qs += `&${key}=${obj[key]}`;
      }
    }
  }
  return qs.startsWith('&') ? qs.slice(1) : qs;;
};

export const trimObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
}

export function getAge(birthday) {
  if (!birthday) return null;
  var ageDifMs = Date.now() - new Date(birthday).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const updateBooleanFields = (obj) => {
  for (let key in obj) {
    if (obj[key] === "true" || obj[key] === "false") {
      obj[key] = getBoolFieldValue(obj[key]);
    }
  }
}

export const getBoolFieldValue = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export function replaceAt(array, index, value) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function getFileNameFromUrl(url) {
  if (!url) return;
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
}

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
